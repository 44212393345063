import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import MainTemplate from 'components/templates/MainTemplate';
import { title } from 'constants/meta';
import { Ul, Ol, Li } from 'components/atoms/List';

const Title = styled.h1`
  font-weight: normal;
`;

const StyledOl = styled(Ol)`
  counter-reset: item;
  margin: 1em 0;
  padding-left: 0;
  text-transform: uppercase;

  & & {
    margin: 0;
    padding-left: 2.5em;
    text-transform: none;
  }
`;

const StyledLi = styled(Li)`
  ${StyledOl} > & {
    display: block;
  }

  ${StyledOl} > &::before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }
`;

const PrivacyPolicyPage = () => (
  <MainTemplate>
    <Helmet>
      <title>{title.privacyPolicy}</title>
      <meta name="keywords" content="" />
      <meta name="description" content="" />
    </Helmet>
    <Title>{title.privacyPolicy}</Title>
    <p>
      Настоящая Политика конфиденциальности персональных данных (далее – Политика
      конфиденциальности) действует в отношении всей информации, которую Интернет-магазин «Золотая
      Камея» (далее – «Золотая Камея»), расположенный на доменном имени http://gold-kamea.ru, может
      получить о Пользователе во время использования данного сайта.
    </p>
    <StyledOl>
      <StyledLi>
        Общие положения
        <StyledOl>
          <StyledLi>
            Использование Пользователем сайта «Золотая Камея» означает согласие с настоящей
            Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
          </StyledLi>
          <StyledLi>
            В случае несогласия с условиями Политики конфиденциальности Пользователь должен
            прекратить использование сайта «Золотая Камея».
          </StyledLi>
          <StyledLi>
            Предоставляя «Золотая Камея» свои персональные данные, Пользователь свободно, своей
            волей дает согласие на передачу, использование и раскрытие его персональных данных
            согласно условиям настоящей Политики конфиденциальности.
          </StyledLi>
          <StyledLi>
            Целью данной Политики конфиденциальности является обеспечение защиты прав и свобод
            человека и гражданина при обработке его персональных данных, в том числе защиты прав на
            неприкосновенность частной жизни, личную и семейную тайну, от несанкционированного
            доступа и разглашения.
          </StyledLi>
          <StyledLi>
            Настоящая Политика конфиденциальности применяется только к сайту «Золотая Камея».
            Интернет-магазин не контролирует и не несет ответственность за сайты третьих лиц, на
            которые Пользователь может перейти по ссылкам, доступным на сайте «Золотая Камея».
          </StyledLi>
          <StyledLi>
            Администрация сайта «Золотая Камея» не проверяет достоверность персональных данных,
            предоставляемых Пользователем сайта «Золотая Камея».
          </StyledLi>
        </StyledOl>
      </StyledLi>
      <StyledLi>
        Предмет политики конфиденциальности
        <StyledOl>
          <StyledLi>
            Предметом данной политики конфиденциальности являются персональные данные Пользователя,
            которые он сознательно и добровольно передает Администрации сайта «Золотая Камея».
          </StyledLi>
          <StyledLi>
            Информация предоставляемая Пользователем на сайте «Золотая Камея»:
            <Ul>
              <StyledLi>Фамилия, имя, отчество</StyledLi>
              <StyledLi>Адрес электронной почты (e-mail)</StyledLi>
              <StyledLi>Телефон</StyledLi>
              <StyledLi>Адрес доставки товара</StyledLi>
            </Ul>
            Также «Золотая Камея» собирает некоторую статистическую информацию, например:
            <Ul>
              <StyledLi>IP-адрес пользователя;</StyledLi>
              <StyledLi>Дата, время и количество посещений;</StyledLi>
              <StyledLi>
                Адрес сайта, с которого пользователь осуществил переход на сайт Компании;
              </StyledLi>
              <StyledLi>
                Информация, предоставляемая Вашим браузером (тип устройства, тип и версия браузера,
                операционная система и т.п.).
              </StyledLi>
            </Ul>
          </StyledLi>
          <StyledLi>
            Любая иная персональная информация неоговоренная выше подлежит надежному хранению и
            нераспространению, за исключением случаев, предусмотренных в п.п.4.3. и 4.4. настоящей
            Политики конфиденциальности.
          </StyledLi>
        </StyledOl>
      </StyledLi>
      <StyledLi>
        Цели сбора персональных данных пользователя
        <StyledOl>
          <StyledLi>
            Персональные данные Пользователя Администрация сайта «Золотая Камея» может использовать
            в целях:
            <StyledOl>
              <StyledLi>
                Идентификации Пользователя для оформления заказа и (или) заключения Договора
                купли-продажи товара дистанционным способом.
              </StyledLi>
              <StyledLi>Создания учетной записи для упрощенного совершения покупок.</StyledLi>
              <StyledLi>
                Осуществления обратной связи с клиентом, предоставления эффективной клиентской
                поддержки.
              </StyledLi>
              <StyledLi>
                Уведомления Пользователя сайта «Золотая Камея» о состоянии Заказа.
              </StyledLi>
              <StyledLi>
                Предоставления, с согласия Пользователя, предложений и информации об акциях,
                поступлениях новинок, рекламных рассылок.
              </StyledLi>
            </StyledOl>
          </StyledLi>
        </StyledOl>
      </StyledLi>
      <StyledLi>
        Сроки и способы обработки персональных данных
        <StyledOl>
          <StyledLi>
            Срок хранения персональных данных соответствует необходимому времени для выполнения
            целей, описываемых в настоящей Политике Конфиденциальности, за исключением случаев,
            когда более длительный период хранения данных и информации необходим в соответствии с
            законодательством либо разрешён им.
          </StyledLi>
          <StyledLi>
            Настоящая Политика предусматривает обработку персональных данных любыми способами,
            предусмотренными законодательством, в том числе путем сбора, записи, систематизации,
            накопления, хранения, уточнения (обновления, изменения), извлечения, использования,
            передачи (распространения, предоставления, доступа), обезличивания, блокирования,
            удаления, уничтожения персональных данных.
          </StyledLi>
          <StyledLi>
            Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные
            данные третьим лицам, в частности, курьерским службам, организациям почтовой связи,
            электронным системам и провайдерам, исключительно в целях выполнения заказа
            Пользователя, оформленного на Сайте «Золотая Камея», включая доставку Товара.
          </StyledLi>
          <StyledLi>
            Персональные данные Пользователя могут быть переданы уполномоченным органам
            государственной власти Российской Федерации только по основаниям и в порядке,
            установленным законодательством Российской Федерации.
          </StyledLi>
        </StyledOl>
      </StyledLi>
      <StyledLi>
        Защита получаемых персональных данных
        <StyledOl>
          <StyledLi>
            Администрация сайта принимает необходимые организационные и технические меры для защиты
            персональной информации Пользователя от неправомерного или случайного доступа,
            уничтожения, изменения, блокирования, копирования, распространения, а также от иных
            неправомерных действий третьих лиц, а именно:
            <Ul>
              <StyledLi>
                Предотвращение несанкционированного доступа к персональным данным Пользователей, а
                также специальных воздействий на носители персональных данных.
              </StyledLi>
              <StyledLi>
                Предотвращение утечки персональных данных по техническим каналам связи и иными
                способами;
              </StyledLi>
              <StyledLi>Обнаружение вторжений, компьютерных атак и их устранение.</StyledLi>
              <StyledLi>Защита от вредоносных программ с помощью актуальных антивирусов.</StyledLi>
            </Ul>
          </StyledLi>
          <StyledLi>
            Администрация сайта совместно с Пользователем принимает все необходимые меры по
            предотвращению убытков или иных отрицательных последствий, вызванных утратой или
            разглашением персональных данных Пользователя.
          </StyledLi>
        </StyledOl>
      </StyledLi>
      <StyledLi>
        Иные положения
        <StyledOl>
          <StyledLi>
            Во всем остальном, что не отражено напрямую в Политике Конфиденциальности, Компания
            обязуется руководствоваться нормами и положениями Федерального закона от 27.07.2006 N
            152-ФЗ «О персональных данных»
          </StyledLi>
          <StyledLi>
            Все предложения, претензии, отзывы или вопросы по настоящей Политике Конфиденциальности
            следует сообщать через почтовое отправление на адрес: 420202, Республика Татарстан, г. Казань, ул. Чернышевского, д. 43/2, офис 424 или на адрес электронной почты info@gold-kamea.ru
          </StyledLi>
          <StyledLi>
            Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без
            согласия Пользователя.
          </StyledLi>
          <StyledLi>
            Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте
            «Золотая Камея», если иное не предусмотрено новой редакцией Политики конфиденциальности.
          </StyledLi>
        </StyledOl>
      </StyledLi>
    </StyledOl>
  </MainTemplate>
);

export default PrivacyPolicyPage;
